
//
// apexcharts.scss
//
.apex-charts {
    min-height: 10px !important;
    text {
        font-family: $font-family-base !important;
        fill: $gray-700;
    }
    .apexcharts-canvas {
        margin: 0 auto;
    }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
    font-family: $font-family-base !important;
}

.apexcharts-legend-series {
    font-weight: $fw-medium;
}

.apexcharts-gridline {
    pointer-events: none;
    stroke: $apex-grid-color;
}

.apexcharts-legend-text {
    color: $gray-600 !important;
    font-family: $font-family-base !important;
    font-size: 13px !important;
}

.apexcharts-pie-label {
    fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
    text {
        font-family: $font-family-base !important;
        fill: $gray-500;
    }
}

[data-layout-mode="dark"] {
    .apexcharts-gridline {
        stroke: var(--#{$prefix}gray-300);
    }

    .apexcharts-radialbar-track.apexcharts-track {
        path {
            stroke: var(--#{$prefix}gray-300);
        }
    }

    .apexcharts-tooltip {
        background-color: var(--#{$prefix}custom-white) !important;
        border: 1px solid var(--#{$prefix}border-color) !important;

        .apexcharts-tooltip-title {
            background-color: var(--#{$prefix}gray-300) !important;
            border-bottom: 1px solid var(--#{$prefix}border-color) !important;
        }
    }

    .apexcharts-pie-area {
        stroke: var(--#{$prefix}gray-300);
    }

    .apexcharts-pie-label {
        fill: var(--#{$prefix}gray-600) !important;
    }
}