.custom-search-input {
    display: block;
    width: 100%;
    padding: 0.47rem 1.75rem 0.47rem 0.75rem;
    font-size: 0.8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    appearance: none;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
  
  
  .custom-search-input-sm {
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    font-size: .7109375rem;
  }

  .custom-search-input:focus {
    border-color: #a6adb4;
    box-shadow: 0 0 0 0.15rem rgba(18,38, 180, 0.25);
    outline: none; /* prevent default outline */
  }
  